import React, { useState } from 'react';
import Back from '../../components/common/back/Back';
import CheckoutSteps from "../../components/Checkout/CheckoutSteps";
import Checkout from "../../components/Checkout/Checkout";
import "./style.css" 

const CheckoutPage = () => {
  const [activeStep, setActiveStep] = useState(1);

  const handleAddStep = () => {
    setActiveStep(prevStep => prevStep + 1);
  };

  return (
    <div>
      <Back title={"cart"} />
      <br />
      <br />
      <CheckoutSteps active={activeStep} />
      <Checkout activeStep={activeStep} handleAddStep={handleAddStep} />
      <br />
      <br />
    </div>
  );
};

export default CheckoutPage;
