import React, { useState, useRef, useEffect } from 'react';
import { MdStars } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/AuthActions';

function DropDown({ open, setOpen, buttonRef }) {
  const panelRef = useRef(null);

  const close = (focusAfter) => {
    if (open) {
      setOpen(false);
      if (focusAfter) focusAfter.focus();
    }
  };

  const handleClickOutside = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      close(buttonRef.current);
    }
  };

  const dispatch = useDispatch()

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const toggle = () => setOpen(!open);

  const { user } = useSelector((state) => state.authReducer.authData);

  const handleLogOut = ()=> {
    dispatch(logout())
  }
  return (
    <div className="flex h-96 justify-center">
      <div className="relative inline-block">
        {/* Button */}
        

        {/* Panel */}
        {open && (
          <div
            ref={panelRef}
            id="dropdown-button"
            className="absolute md:-left-5 lg:left-15 sm:left-10 -left-10 z-10 mt-2 w-60 divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white text-left text-sm shadow-lg"
          >
            <div className="py-3 px-4">
              <div className="flex items-center gap-3">
                <div className="relative h-10 text-3xl text-blue-800 w-10">
                <MdStars className="star" />
                </div>
                <div className="text-sm">
                  <div className="font-medium text-gray-700">{user.username}</div>
                  <div className="text-gray-400">{user.points} Points</div>
                </div>
              </div>
            </div>
            <div className="p-1">
              <a
                href="/best-Users"
                className="flex w-full items-center gap-2 rounded-md px-3 py-2 text-gray-700 hover:bg-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
                View best Users
                <span className="inline-flex flex-1 justify-end gap-1 text-xs capitalize text-gray-400">
                  <kbd className="min-w-[1em] font-sans">⌥</kbd>
                  <kbd className="min-w-[1em] font-sans">⇧</kbd>
                  <kbd className="min-w-[1em] font-sans">P</kbd>
                </span>
              </a>
              <a
                href="/Doctors"
                className="flex w-full items-center gap-2 rounded-md px-3 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
              >
	Doctors
                <span className="inline-flex flex-1 justify-end gap-1 text-xs capitalize text-gray-400">
                  <kbd className="min-w-[1em] font-sans">⌥</kbd>
                  <kbd className="min-w-[1em] font-sans">⇧</kbd>
                  <kbd className="min-w-[1em] font-sans">S</kbd>
                </span>
              </a>
              <a
                href="/Laboratories"
                className="flex w-full items-center gap-2 rounded-md px-3 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
              >
           
                Laboratories
                <span className="inline-flex flex-1 justify-end gap-1 text-xs capitalize text-gray-400">
                  <kbd className="min-w-[1em] font-sans"> ^l </kbd>
                  <kbd className="min-w-[1em] font-sans"> ^g </kbd>
                  <kbd className="min-w-[1em] font-sans">S</kbd>
                </span>
              </a>

            </div>
            <div className="p-1">
              <a
                href="/contact"
                className="flex w-full items-center gap-2 rounded-md px-3 py-2 text-gray-700 hover:bg-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
                Help Center
                <span className="inline-flex flex-1 justify-end gap-1 text-xs capitalize text-gray-400">
                  <kbd className="min-w-[1em] font-sans">?</kbd>
                </span>
              </a>
              <a
                href="/create-Article"
                className="flex w-full bg-blue-600 items-center gap-2 rounded-md px-3 py-2 text-white hover:bg-blue-500 transition-all"
              >
                
                Share an Article
                <span className="inline-flex flex-1 justify-end gap-1 text-xs capitalize  text-gray-400">
                  <kbd className="min-w-[1em] font-sans">⌥</kbd>
                  <kbd className="min-w-[1em] font-sans">⇧</kbd>
                  <kbd className="min-w-[1em] font-sans">Q</kbd>
                </span>
              </a>
              <div
                onClick={handleLogOut}
                className="flex w-full mt-2 bg-blue-600 items-center gap-2 rounded-md px-3 py-2 text-white hover:bg-blue-500 transition-all"
              >
                
                Log out
                <span className="inline-flex flex-1 justify-end gap-1 text-xs capitalize  text-gray-400">
                  <kbd className="min-w-[1em] font-sans">⌥</kbd>
                  <kbd className="min-w-[1em] font-sans">⇧</kbd>
                  <kbd className="min-w-[1em] font-sans">Q</kbd>
                </span>
              </div>
              
            </div>
            
          </div>
        )}
      </div>
    </div>
  );
}

export default DropDown;
