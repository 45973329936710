export const addTocart = (data) => async (dispatch) => {
    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || []; // Initialize as empty array if null or invalid
  
    if (!Array.isArray(cartItems)) {
      cartItems = []; // Reset to empty array if cartItems is not an array
    }
  
    const isItemExist = cartItems.find((item) => item._id === data._id);
  
    if (isItemExist) {
      cartItems = cartItems.map((item) => (item._id === data._id ? data : item));
    } else {
      cartItems.push(data);
    }
  
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  
    dispatch({
      type: "addToCart",
      payload: data,
    });
  
    return data;
  };


  export const getCartItems = () => async (dispatch) => {
    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    
    if (!Array.isArray(cartItems)) {
      cartItems = [];
    }
    
    dispatch({
      type: "getCartItems",
      payload: cartItems,
    });
    
    return cartItems;
  };
  
  
  export const removeFromCart = (id) => async (dispatch) => {
    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || []; // Initialize as empty array if null or invalid
  
    if (!Array.isArray(cartItems)) {
      cartItems = []; // Reset to empty array if cartItems is not an array
    }
  
    cartItems = cartItems.filter((item) => item._id !== id);
  
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  
    dispatch({
      type: "removeFromCart",
      payload: id,
    });
  
    return id;
  };
  