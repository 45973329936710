import React, { useState } from "react";
import { Modal, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from '@mantine/hooks';

import "./ProfileModal.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadImage } from "../../actions/UploadAction";
import { updateUser } from "../../actions/UserAction";
import uploadImageToCloudinary from "../../utils/uploadCloudinary"; // corrected typo


const ProfileModal = ({ modalOpened, setModalOpened, data }) => {
  const theme = useMantineTheme();
  const { password, ...other } = data;
  const [formData, setFormData] = useState(other);
  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelecetedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const dispatch = useDispatch();
  const param = useParams();

  const { user } = useSelector((state) => state.authReducer.authData);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    setIsUploading(true);
    const data = await uploadImageToCloudinary(file);
    setIsUploading(false);
    setPreviewUrl(data.url);
    setSelecetedFile(data.url);
    setProfileImage(data.url);
  };

  // form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    let UserData = formData;
    if (profileImage) {
      UserData.profilePicture = profileImage;
      try {
        dispatch(uploadImage(data));
      } catch (err) {
        console.log(err);
      }
    }
    if (coverImage) {
      const data = new FormData();
      const fileName = Date.now() + coverImage.name;
      data.append("name", fileName);
      data.append("file", coverImage);
      UserData.coverPicture = fileName;
      try {
        dispatch(uploadImage(data));
      } catch (err) {
        console.log(err);
      }
    }
    dispatch(updateUser(param.id, UserData));
    setModalOpened(false);
  };
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  return (
    <Modal
    overlayColor={
      theme.colorScheme === 'dark'
        ? theme.colors.dark[9]
        : theme.colors.gray[2]
    }
    overlayOpacity={0.55}
    overlayBlur={3}
    size={isSmallScreen ? '80%' : '55%'}
    opened={modalOpened}
    onClose={() => setModalOpened(false)}
  >
      <form className="infoForm" onSubmit={handleSubmit}>
        <h3>Your Info</h3>
        <div>
          <input
            value={formData.firstname}
            onChange={handleChange}
            type="text"
            placeholder="First Name"
            name="firstname"
            className="infoInput"
          />
          <input
            value={formData.lastname}
            onChange={handleChange}
            type="text"
            placeholder="Last Name"
            name="lastname"
            className="infoInput"
          />
        </div>

        <div className="spesitalisation">
          <input
            value={formData.specialisation}
            onChange={handleChange}
            type="text"
            placeholder="specialisation"
            name="specialisation"
            className="infoInput"
          />
        </div>

        <div>
          <input
            value={formData.phone}
            onChange={handleChange}
            type="text"
            placeholder="phone number"
            name="phone"
            className="infoInput"
          />
          {user.role === "student" &&<input
            value={formData.university}
            onChange={handleChange}
            type="text"
            placeholder="university"
            name="university"
            className="infoInput"
          />}
        </div>
        {user.role === "student" && <div>
        <input
            value={formData.year}
            onChange={handleChange}
            type="text"
            placeholder="year"
            name="year"
            className="infoInput"
          />
        </div>}

        

        {!previewUrl && <div className="createPost">
            
            <div className="mx-auto max-w-xs">
              <label for="profilePicture" className="mb-1 block text-sm font-medium text-gray-700">Upload file</label>
              <input id="profilePicture" onChange={handleFileInputChange} name="profilePicture" type="file" className="block w-full text-sm file:mr-4 file:rounded-md file:border-0 file:bg-primary-500 file:py-2.5 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-primary-700 focus:outline-none disabled:pointer-events-none disabled:opacity-60" />
            </div>
            
                        </div>}
                        {previewUrl &&  <>
                        <div className="avatar">
                          <div className="h-10 w-10">
                <img className="h-full w-full rounded-full object-cover object-center" src={previewUrl} alt="" />
              </div></div>
                        </>}

        <button className="button infoButton" type="submit">
          Update
        </button>
      </form>
    </Modal>
  );
};

export default ProfileModal;
