import React, { useState } from "react"
import { Link } from "react-router-dom"
import Head from "./Head"
import "./header.css"
import { RxHamburgerMenu } from "react-icons/rx";
import { LiaTimesSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../actions/AuthActions";
import { AiOutlineShoppingCart } from "react-icons/ai";



const Header = () => {
  const dispatch = useDispatch()
  const [click, setClick] = useState(false)
  const user = useSelector((state) => state.authReducer.authData);
  const handleLogOut = ()=> {
    dispatch(logout())
  }


  return (
    <> 
    
      <Head />
     
      <header>

        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/products'>All Products</Link>
            </li>
            <li>
              <Link to='/cart'>Cart</Link>
            </li>
            

            {!user && <><li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/team'>Team</Link>
            </li>
            <li>
              <Link to='/pricing'>Pricing</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li></>}
            {user?.user?.isAdmin  && 
            <li>
              <Link to='/add-product'>Add Product</Link>
            </li>}
            {user?.user?.isAdmin && 
            <li>
              <Link to='/Dashboard'>Dashboard</Link>
            </li>}
          </ul>
          <div className='start'>
            <div >{!user ? <a className='auth' href="/SignUp">Register Now</a>:<div onClick={handleLogOut} className='auth pointer'>Log Out</div>}</div>
          </div>
          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? <LiaTimesSolid />: <RxHamburgerMenu />}
          </button>
        </nav>
      </header>

    </>
  )
}

export default Header




