import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllLaboratories } from "../../../api/UserRequests";
import { createChat } from "../../../api/ChatRequests"; 
import { followUser, unfollowUser } from "../../../actions/UserAction";
import "./style.css";

function Doctors() {
  const dispatch = useDispatch();
  const [persons, setPersons] = useState([]);
  const [followingStatus, setFollowingStatus] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authReducer.authData);

  useEffect(() => {
    const fetchPersons = async () => {
      const { data } = await getAllLaboratories();
      setPersons(data);

      const initialFollowingStatus = data.reduce((acc, person) => {
        acc[person._id] = person.followers.includes(user._id);
        return acc;
      }, {});

      setFollowingStatus(initialFollowingStatus);
    };
    fetchPersons();
  }, [user._id]);

  const handleContactClick = async (receiverId) => {
    try {
      const { data } = await createChat({ senderId: user._id, receiverId });
      navigate('/chat');
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  const handleFollow = (personId) => {
    const isFollowing = followingStatus[personId];

    if (isFollowing) {
      dispatch(unfollowUser(personId, user));
    } else {
      dispatch(followUser(personId, user));
    }

    setFollowingStatus((prevState) => ({
      ...prevState,
      [personId]: !isFollowing
    }));
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPersons = persons.filter((person) => {
    const fullName = `${person.firstname} ${person.lastname}`.toLowerCase();
    const specialisation = person.specialisation?.toLowerCase();
    const username = person.username?.toLowerCase();
    const query = searchQuery.toLowerCase();

    return (
      fullName.includes(query) ||
      (specialisation && specialisation.includes(query)) || username.includes(query)
    );
  });
  return (
    <div className='Laboratories'>
      <h1 className='find'>Find A Laboratory</h1>
      <div className="Doctor container justify-between max-w-[570px] mt-[30px] mx-auto flex items-center">
        <form className="Doctor-form">   
          <label htmlFor="default-search" className="Doctor-label">Search</label>
          <div className="Doctor-relative">
            <div className="Doctor-absolute Doctor-inset-y-0 Doctor-start-0 Doctor-flex Doctor-items-center Doctor-pointer-events-none">
              <svg className="Doctor-svg" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="Doctor-input"
              placeholder="Search for a Laboratory by full name , username or specialist"
              value={searchQuery}
              onChange={handleSearch}
              required
            />
            <button className="Doctor-button">Search</button>
          </div>
        </form>
      </div>
      <div className="container">
        {persons.map((person) => {
          if (person._id !== user._id) {
            return (
              <div key={person._id} className="box">
                <div className="image">
                  <a href={`/profile/${person._id}`}><img src={person.profilePicture} alt="profile pic" /></a>
                </div>
                <a href={`profile/${person._id}`}><div className="name_job">{person.firstname} {person.lastname}</div></a>
                <div className="username">{person.username}</div>
                <p>{person.website}</p>
                <div className="btns">
                  <button onClick={() => handleContactClick(person._id)}>Contact</button>
                  <button
                    className={
                      followingStatus[person._id] ? "button fc-button UnfollowButton" : "button fc-button"
                    }
                    onClick={() => handleFollow(person._id)}
                  >
                    {followingStatus[person._id] ? "Unfollow" : "Follow"}
                  </button>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default Doctors;
