import axios from 'axios';

const API = axios.create({ baseURL: 'https://labmeddz.com/api' });

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});



export const getSlides = () => API.get(`/slide`);

export const deleteSlide = (id) => API.delete(`/slide/${id}`);



