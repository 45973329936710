import * as UploadApi from "../api/UploadRequest";

export const uploadImage = (data) => async (dispatch) => {
  try {
    console.log("Image upload Action start ho gya hy")
    await UploadApi.uploadImage(data);
  } catch (error) {
    console.log(error);
  }
};

export const uploadPost = (data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    const newPost = await UploadApi.uploadPost(data);
    dispatch({ type: "UPLOAD_SUCCESS", data: newPost.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};

export const updatePost = (id, updatedPost) => async (dispatch) => {

  dispatch({type: "UPDATING_START"})
  try{
      const {data} = await UploadApi.updatePost(id, updatedPost);
      console.log("Action ko receive hoa hy ye : ",data)
      dispatch({type: "UPDATING_SUCCESS", data: data})
  }   
  catch(error){
      dispatch({type: "UPDATING_FAIL"})
  }
}

export const deleteArticle = (id, deletedPost) => async (dispatch) => {
  dispatch({ type: "UPDATING_START" });
  try {
    const { data } = await UploadApi.deletedPost(id, deletedPost);
    console.log("Action ko receive hoa hy ye : ", data);
    dispatch({ type: "UPDATING_SUCCESS", data: data });
  } catch (error) {
    dispatch({ type: "UPDATING_FAIL" });
    console.error("Error deleting the post:", error);
  }
};


export const uploadProduct = (data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    const newPost = await UploadApi.createProduct(data);
    dispatch({ type: "UPLOAD_SUCCESS", data: newPost.data });
    return { success: true, data: newPost.data }; // Return success response
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
    return { success: false, error }; // Return failure response
  }
};


export const uploadSlide = (data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    const newPost = await UploadApi.uploadSlide(data);
    dispatch({ type: "UPLOAD_SUCCESS", data: newPost.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};


export const uploadOrder = (data) => async (dispatch) => {
  dispatch({ type: "UPLOAD_START" });
  try {
    const newPost = await UploadApi.createOrder(data);
    dispatch({ type: "UPLOAD_SUCCESS", data: newPost.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "UPLOAD_FAIL" });
  }
};



