import React from 'react';
import styles from '../../styles/styles';
import classNames from 'classnames';

const CheckoutSteps = ({ active }) => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-[90%] 800px:w-[50%] flex items-center flex-wrap">
        <div className={classNames(styles.noramlFlex, "flex items-center")}>
          <div className={styles.cart_button}>
            <span className={styles.cart_button_text}>1. Cart / Info</span>
          </div>
          <div className={classNames(
            "w-[30px] 800px:w-[70px] h-[4px]",
            { "!bg-[#3656ad]": active > 1, "!bg-[#FDE1E6]": active <= 1 }
          )} />
        </div>

        <div className={classNames(styles.noramlFlex, "flex items-center")}>
          <div className={classNames(
            styles.cart_button,
            { "!bg-[#FDE1E6]": active <= 1 }
          )}>
            <span className={classNames(
              styles.cart_button_text,
              { "!text-[#545fff]": active <= 1 }
            )}>
              2. Success
            </span>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default CheckoutSteps;
