import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./BestUsers.css";
import { MdStars } from "react-icons/md";
import { getDoctorsAndStudentsByRating } from "../../api/UserRequests";

const TrendCard = () => {
  const [persons, setPersons] = useState([]);
  const { user } = useSelector((state) => state.authReducer.authData);

  useEffect(() => {
    const fetchPersons = async () => {
      const { data } = await getDoctorsAndStudentsByRating();
      setPersons(data);
    };
    fetchPersons();
  }, [user._id]);

  return (
    <div className="TrendCard">
      <div className="Head">
         <a href="/best-Users"><h3>{`Top Users`}</h3></a>
        <MdStars className="star" />
      </div>

      {persons.map((person, id) => {
        return (
          <div className="interest" key={id}>
            <div className="">
              <img
                className=""
                src={person.profilePicture}
                alt=""
              />
            </div>

            <span>@{person.username}</span>
            <span className="starspan">
              {person.points} <MdStars className="star" />{" "}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default TrendCard;
