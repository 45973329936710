import React, { useRef, useState } from "react";
import Posts from "../Posts/Posts";
import PostShare from "../PostShare/PostShare";
import "./PostSide.css";
import NavIconsPost from "../NavIconsPost/NavIcons";
import ProfileCardPost from "../ProfileCardPost//ProfileCard"
import DropDown from "../DropDown/DropDown";

const PostSide = () => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="PostSide">
      <ProfileCardPost />
      <PostShare toggleDropdown={toggleDropdown} />
      <DropDown open={open} buttonRef={buttonRef} />
      <Posts/>
      <NavIconsPost />
    </div>
  );
};

export default PostSide;
