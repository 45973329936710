import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile/Profile";
import { useSelector } from "react-redux";
import Chat from "./pages/Chat/Chat";
import Landing from "../src/components/home/Home"
import Auth from "../src/pages/Auth/Auth"
import  Header  from "../src/components/common/header/Header";
import  Footer  from "../src/components/common/footer/Footer";
import About from "../src/components/about/About"
import CourseHome from "../src/components/allcourses/CourseHome"
import Team from "../src/components/team/Team"
import Pricing from "../src/components/pricing/Pricing"
import Blog from "../src/components/blog/Blog"
import Contact from "../src/components/contact/Contact"
import Create from "./pages/create/Create";
import SignUp from "./pages/SignUp/SignUp"
import Register from "./pages/DocAuth/Register";
import LabRegister from "./pages/LabAuth/Register.jsx";
import MedRegister from "./pages/StuAuth/Register.jsx";
import Article from "./pages/Article/Article.jsx";
import Doctors from "./pages/Users/Doctors/Doctors.jsx"
import Laboratories from "./pages/Users/Laboratories/Laboratories.jsx"
import AddProducts from "./pages/AddProducts/AddProducts.jsx";
import Product from "./pages/SinglePageProduct/Product.jsx";
import Privacy from "./pages/Privacy/Privacy.jsx";
import CheckoutPage from "./pages/checkout/CheckoutPage.jsx";
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import EditPost from "./pages/editPost/EditPost.jsx";
import Best from "./components/best/Best.jsx";



function App() {
  const user = useSelector((state) => state.authReducer.authData);
  return (
    <div
      className="App"
      style={{
        height:
          window.location.href === "http://vserver-client37.dzsecurity.net/chat"
            ? "calc(100vh - 2rem)"
            : "auto",
      }}
    >
      <div className="blur" style={{ top: "-18%", right: "0" }}></div>
      <div className="blur" style={{ top: "36%", left: "-8rem" }}></div>
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="home" /> : <Navigate to="landing-page" />}
        />
        <Route
          path="/home"
          element={user ? <Home /> : <Navigate to="../landing-page" />}
        />

        <Route
          path="/Articles/:id"
          element={user ? <Article /> : <Navigate to="../landing-page" />}
        />
        <Route
          path="/products/:id"
          element={
            <>
            <Header/>
          <Product />
          <Footer/>
          </>
        }
        />
        <Route
          path="/add-product"
          element=
          {
            <>
           <Header/>
          {user?.user?.isAdmin  ? <AddProducts /> : <Navigate to="../products" />}
          <Footer/>
          </>
        }
        />
        
        <Route
          path="/landing-page"
          element={
         <>
         <Header/>
          {user ? <Navigate to="../home" /> : <Landing />}
          <Footer/>
        </>
        }
        />
        <Route
          path="/auth"
          element=
          {
            <>
            
          {<Auth />}
          </>
          }
        />
        <Route
          path="/SignUp"
          element=
          {
            <>
            
          {<SignUp />}
          </>
          }
        />
        <Route
          path="/Doctor"
          element=
          {
            <>
            
          {<Register />}
          </>
          }
        />
        <Route
          path="/medStudent"
          element=
          {
            <>
            
          {<MedRegister />}
          </>
          }
        />
        <Route
          path="/Laboratory"
          element=
          {
            <>
            
          {<LabRegister />}
          </>
          }
        />



        

        <Route
          path="/create-Article"
          element={user ?  <Create /> : <Navigate to="../landing-page" />}
        />
<Route
          path="/editArticle/:id"
          element=
          {
            <>
            
          {<EditPost />}
          </>
          }
        />
<Route
          path="/best-Users"
          element={user ? <Best /> : <Navigate to="../landing-page" />}
        />

        <Route

          path="/about"

          element=
          {
          <>
          <Header/>
          {<About />}
          <Footer/>
          </>
        }
        />
        <Route
          path="/products"
          element=
          {
          <>
          <Header/>
          {<CourseHome />}
          <Footer/>
          </>
        }        />
        <Route
          path="/team"
          element=
          {
          <>
          <Header/>
          {<Team />}
          <Footer/>
          </>
        }  />

        <Route
          path="/pricing"
          element=
          {
          <>
          <Header/>
          {<Pricing />}
          <Footer/>
          </>
        }        />
        <Route
          path="/journal"
          element=
          {
          <>
          <Header/>
          {<Blog />}
          <Footer/>
          </>
        }        />
        <Route
          path="/contact"
          element=
          {
          <>
          <Header/>
          {<Contact />}
          <Footer/>
          </>
        } />
         <Route
          path="/Privacy"
          element=
          {
          <>
          <Header/>
          {<Privacy />}
          <Footer/>
          </>
        } />

<Route
          path="/cart"
          element=
          {
          <>
          <Header/>
          {<CheckoutPage />}
          <Footer/>
          </>
        } />

        <Route
          path="/profile/:id"
          element={user ? <Profile /> : <Navigate to="../landing-page" />}
        />

        <Route
          path="/Doctors"
          element={user ? <Doctors /> : <Navigate to="../landing-page" />}
        />

        <Route
          path="/Laboratories"
          element={user ? <Laboratories /> : <Navigate to="../landing-page" />}
        />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />

        <Route
          path="/chat"
          element={user ? <Chat /> : <Navigate to="../landing-page" />}
        />
        <Route
          path="/Dashboard"
          element={user?.user?.isAdmin ? <Dashboard /> : <Navigate to="../landing-page" />}
        />




      </Routes>

    </div>
  );
}

export default App;
