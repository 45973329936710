import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading subtitle='WELCOME TO MED-LAB' title='Best Online PLATFROM that connects Doctors & laboratories' />
            <p className="hidden">The 	&quot;Lab-Med&quot; platform offers a revolutionary solution that directly connects pharmaceutical laboratories, doctors (physicians and pharmacists), and medical students.</p>
            <div className='z-index'>
            <a className="href" href="/auth">
              <button className='primary-btn hidden'>
                GET STARTED NOW <i className='fa fa-long-arrow-alt-right'></i>

              </button>
              </a>
              <a className="href" href="/auth">
              <button >
                HOW IT WORKS <i className='fa fa-long-arrow-alt-right'></i>
              </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
