import axios from 'axios';

const API = axios.create({ baseURL: 'https://labmeddz.com/api' });

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});

export const getPost = (id) => API.get(`/posts/${id}`);
export const getTimelinePosts = (id) => API.get(`/posts/${id}/timeline`);
export const likePost = (id, userId) => API.put(`posts/${id}/like`, { userId: userId });

// Add a comment to a post
export const addComment = (postId, commentData) => API.post(`/posts/${postId}/comment`, commentData);

// Get comments for a post
export const getComments = (postId) => API.get(`/posts/${postId}/comments`);


// get Posts of interests first 

export const getPosts = (id) => API.get(`/posts/interests/${id}`);


// get Admin Posts

export const getAdminPosts = (id) => API.get(`/posts`);


