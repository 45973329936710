import React, { useState, useEffect } from 'react';
import { getPost } from '../../api/PostsRequests'; // Adjust the path as needed
import "./style.css";
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Article() {
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};
    const { id } = useParams(); // Extracting the ID from the URL params
    const [post, setPost] = useState(null);
const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");
  
    useEffect(() => {
      const fetchPost = async () => {
        try {
          const { data } = await getPost(id); // Fetch post using the extracted ID
          setPost(data);
        } catch (error) {
          console.error("Error fetching the post:", error);
        }
      };
  
      fetchPost();
    }, [id]);
  
    if (!post) return <div>Loading...</div>;

 const handleImageClick = (imageUrl) => {
    setLightboxImage(imageUrl);
    setIsLightboxOpen(true);
  };


    return (
      <div className="article">
  <div className="article mx-auto">
    <section className="article mx-auto mb-8 p-8 bg-gray-300 pl-16 pr-16 rounded-lg hover:shadow-lg transition-all container w-90-percent md-w-60-percent">
      <div className="article">
        <div className="article flex justify-between flex-col md-flex-row items-center">
                    <h3 className="article mb-5 font-semibold">{formatDate(post.createdAt)}</h3>
        </div>
        <h1 className="article mt-4 ov text-3xl font-bold mb-3">{post.title}</h1>
        <div className="article overflow-hidden h-64">
         <img
                className="article w-full h-full object-contain rounded-md mb-6"
                src={`${post.image ? process.env.REACT_APP_PUBLIC_FOLDER + post.image : ""}`}
                alt="thumbnail"
                style={{cursor:"pointer"}}
                onClick={() => handleImageClick(process.env.REACT_APP_PUBLIC_FOLDER + post.image)}
              />
        </div>
        <p className="article" dangerouslySetInnerHTML={{ __html: post.desc }}></p>
      </div>
      
    </section>
  </div>
  
{isLightboxOpen && (
        <Lightbox
          mainSrc={lightboxImage}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
</div>

    );
  }
  
  export default Article;
