import React, { useState } from "react";
import "./PostShare.css";
import { IoIosCopy } from "react-icons/io";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PiDotsNineBold } from "react-icons/pi";
import { useSelector } from "react-redux";

const PostShare = ({ toggleDropdown }) => {
  const [copied, setCopied] = useState(false);
  const { user } = useSelector((state) => state.authReducer.authData);
  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;

  return (
    <div className="PostShare">
      <img
        src={
          user.profilePicture
            ? user.profilePicture
            : serverPublic + "defaultProfile.png"
        }
        alt="Profile"
      />
      <div>
        <input
          type="text"
          className="Promo"
          placeholder={user.MycodePromo + "(your code promo)"}
          readOnly
        />
      </div>
      <CopyToClipboard
        text={user.MycodePromo}
        onCopy={() => setCopied(true)}
      >
        <IoIosCopy className="Copy" />
      </CopyToClipboard>
      {copied && <span style={{ color: 'green' }}>Copied!</span>}
      <span className="ninepoints" onClick={toggleDropdown}>
        <PiDotsNineBold />
      </span>
    </div>
  );
};

export default PostShare;
