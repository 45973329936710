import React, { useEffect, useState, useRef } from "react";
import { addMessage, getMessages } from "../../api/MessageRequests";
import { getUser } from "../../api/UserRequests";
import { useDispatch } from "react-redux";
import InputEmoji from 'react-input-emoji';
import { format } from "timeago.js";
import "./ChatBox.css";
import { uploadImage } from "../../actions/UploadAction";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';




const ChatBox = ({ chat, currentUser, setSendMessage, receivedMessage }) => {
  const [userData, setUserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const scroll = useRef();
  const imageRef = useRef();
  const [isUploading, setIsUploading] = useState(false);


 const [isLightboxOpen, setIsLightboxOpen] = useState(false);
 const [lightboxImage, setLightboxImage] = useState("");


  useEffect(() => {
    const userId = chat?.members?.find((id) => id !== currentUser);
    const getUserData = async () => {
      try {
        const { data } = await getUser(userId);
        setUserData(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (chat !== null) getUserData();
  }, [chat, currentUser]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const { data } = await getMessages(chat._id);
        setMessages(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (chat !== null) fetchMessages();
  }, [chat]);

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleChange = (newMessage) => {
    setNewMessage(newMessage);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!newMessage && !image) return; // Prevent sending empty messages

const message = {
        senderId: currentUser,
        text: newMessage,
        chatId: chat._id,
    };

    
    if (image) {
      const data = new FormData();
      const fileName = Date.now() + image.name;
      data.append("name", fileName);
      data.append("file", image);
      message.imageUrl = fileName;
      setIsUploading(true);

      try {
        await dispatch(uploadImage(data));
      } catch (err) {
        console.log(err);
      }
      setIsUploading(false);

    }

    

    const receiverId = chat.members.find((id) => id !== currentUser);
    setSendMessage({ ...message, receiverId });

    try {
        const { data } = await addMessage(message);
        setMessages([...messages, data]);
        setNewMessage("");
        setImage(null);
    } catch (error) {
        console.log("Error sending message:", error);
    }
};




  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };
  

  useEffect(() => {
    if (receivedMessage !== null && receivedMessage.chatId === chat._id) {
      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
    }
  }, [receivedMessage]);


const handleImageClick = (imageUrl) => {
  setLightboxImage(imageUrl);
  setIsLightboxOpen(true);
};



  return (
    <div className="ChatBox-container">
      {chat ? (
        <>
          {/* Chat header */}
          <div className="chat-header">
            <div className="follower">
              <div>
                <img
                  src={
                    userData?.profilePicture
                      ? userData.profilePicture
                      : process.env.REACT_APP_PUBLIC_FOLDER + "defaultProfile.png"
                  }
                  alt="Profile"
                  className="followerImage"
                  style={{ width: "50px", height: "50px", borderRadius: "99999px" }}
                />
                <div className="name" style={{ fontSize: "0.9rem" }}>
                  <span>
                    {userData?.firstname} {userData?.lastname}
                  </span>
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "95%",
                border: "0.1px solid #ececec",
                marginTop: "20px",
              }}
            />
          </div>
          {/* Chat body */}
          <div className="chat-body">
            {messages.map((message) => (
              <div
                key={message._id}
                className={message.senderId === currentUser ? "message own" : "message"}
              >
                <span>{message.text}</span>
                <span>{format(message.createdAt)}</span>
                {message.imageUrl && (
             <img
                    src={process.env.REACT_APP_PUBLIC_FOLDER + message.imageUrl}
                    alt="Uploaded"
                    style={{cursor:"pointer",width:"100%",height:"200px"}}
                    onClick={() => handleImageClick(process.env.REACT_APP_PUBLIC_FOLDER + message.imageUrl)}
                  />

                )}
              </div>
            ))}
            <div ref={scroll} />
          </div>
          {/* Chat sender */}
          <div className="chat-sender">
            <div onClick={() => imageRef.current.click()}>+</div>
            <InputEmoji value={newMessage} onChange={handleChange} />
            {image && (
              <div className="image-preview">
                <img style={{width:"30px",height:"30px"}} src={URL.createObjectURL(image)} alt="Selected" />
              </div>
            )}
            <div className="send-button button" onClick={handleSend}>
              Send
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              ref={imageRef}
              onChange={handleImageChange}
            />
            {isUploading && <span>Uploading...</span>}
          </div>
        </>
      ) : (
        <span className="chatbox-empty-message">
          Tap on a chat to start a conversation...
        </span>
      )}
{isLightboxOpen && (
        <Lightbox
          mainSrc={lightboxImage}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
    </div>
  );
};

export default ChatBox;
