import React, { useEffect, useState } from "react";
import "./ProfileCard.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as UserApi from "../../api/UserRequests.js";

const ProfileCard = ({ location }) => {
  
  const { user: currentUser } = useSelector((state) => state.authReducer.authData);
  const posts = useSelector((state) => state.postReducer.posts);
  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
  const [profileUser, setProfileUser] = useState(currentUser);
  const params = useParams();
  const profileUserId = params.id;
  const { user } = useSelector((state) => state.authReducer.authData);
  const isMyProfile = location === "profilePage";

  useEffect(() => {
    const fetchProfileUser = async () => {
      if (profileUserId === user._id) {
        setProfileUser(user);
      } else {
        console.log("fetching")
        const profileUser = await UserApi.getUser(profileUserId);
        setProfileUser(profileUser?.data);
      }
    };
    fetchProfileUser();
  }, [user]);

  return (
    <div className={`ProfileCardPost ${params?.id ? "hidden" : ""}`}>
      <div className="ProfileImages">
        <img
          className="cover"
          src={serverPublic + "defaultCover.jpg"}
          alt="CoverImage"
        />
        <img
          className="profilePicture"
          src={
            profileUser?.profilePicture
              ? profileUser?.profilePicture
              : serverPublic + "defaultProfile.png"
          }
          alt="ProfileImage"
        />
      </div>
      <div className="ProfileName">
        <span>
          {profileUser.firstname} {profileUser?.lastname}
        </span>
        <span>@{profileUser.username ? profileUser?.username : "Write about yourself"}</span>
      </div>

      <div className="followStatus">
        <hr />
        <div>
          <div className="follow">
            <span>{profileUser.followers?.length}</span>
            <span>Followers</span>
          </div>
          <div className="vl"></div>
          <div className="follow">
            <span>{profileUser.following?.length}</span>
            <span>Following</span>
          </div>
          <div className="vl"></div>
          <div className="follow">
            <span>{profileUser?.points}</span>
            <span>Points</span>
          </div>
          {isMyProfile && (
            <>
              <div className="vl"></div>
              <div className="follow">
                <span>{posts.filter((post) => post?.userId === profileUser?._id)?.length}</span>
                <span>Posts</span>
              </div>{" "}
            </>
          )}
        </div>
        <hr />
      </div>

      {!isMyProfile && (
        <span>
          <Link
            to={`/profile/${profileUser?._id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {profileUser?._id === currentUser?._id ? "My Profile" : "View Profile"}
          </Link>
        </span>
      )}
    </div>
  );
};

export default ProfileCard;
