import React from 'react'
import './TrendCard.css'
import {TrendData} from '../../Data/TrendData.js'
import { useSelector } from 'react-redux';

const TrendCard = () => {
    const { user } = useSelector((state) => state.authReducer.authData);


  return (
   <div className="TrendCard">
       <h3>{"Categories (My interests)"} </h3>


       {user.interests.map((interest, id)=>{
            return(
                <div className="interest" key={id}>
                    <span>#{interest}</span>
                </div>
            )
       })}
   </div>
  )
}

export default TrendCard