import axios from "axios";

const API = axios.create({ baseURL: "https://labmeddz.com/api" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

export const uploadImage = (data) => API.post("/upload/", data);
export const uploadPost = (data) => API.post("/posts", data);
export const createProduct = (productData) => API.post('/products/create-product', productData);
export const uploadSlide = (data) => API.post("/slide", data);
export const updatePost = (id,updatedPost) => API.put(`/posts/${id}`,updatedPost);
export const deletedPost = (id, deletedPost) => API.delete(`/posts/${id}`, deletedPost);
export const createOrder = (OrderData) => API.post('/products/create-Order', OrderData);
