import React, { useState } from "react";
import "./style.css";
import Logo from "../../img/logo.png";
import { logIn, signUp } from "../../actions/AuthActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import uploadImageToCloudinary from "../../utils/uploadCloudinary.js"; // corrected typo


const Register = () => {
  const initialState = {
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    confirmpass: "",
    role: "student",
    phone: "",
    interests: [],
    codePromo: "",
    university:"",
    year: "",
    profilePicture: ""
  };
  const loading = useSelector((state) => state.authReducer.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSignUp, setIsSignUp] = useState(false);

  const [formdata, setData] = useState(initialState);

  const [confirmPass, setConfirmPass] = useState(true);
  const [selectedFile, setSelecetedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Add state for error message

  const interestOptions = [
    { value: "Internal Medicine", label: "Internal Medicine" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Surgery", label: "    Surgery" },
    { value: "Obstetrics and Gynecology", label: "Obstetrics and Gynecology" },
    { value: "Psychiatry", label: "Psychiatry" },
    { value: "Anesthesiology", label: "Anesthesiology" },
  ];

  // Reset Form
  const resetForm = () => {
    setData(initialState);
    setConfirmPass(true);
    setErrorMessage(""); // Reset error message
  };

  // handle Change in input
  const handleChange = (e) => {
    setData({ ...formdata, [e.target.name]: e.target.value });
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    setIsUploading(true);
    const data = await uploadImageToCloudinary(file);
    setIsUploading(false);
    setPreviewUrl(data.url);
    setSelecetedFile(data.url);
    setData({ ...formdata, profilePicture: data.url });
  };

  // handle Change in interests
  const handleInterestsChange = (selectedOptions) => {
    setData({ ...formdata, interests: selectedOptions.map(option => option.value) });
  };

  // Form Submission
  const handleSubmit = async (e) => {
    setConfirmPass(true);
    e.preventDefault();
    if (isSignUp) {
      if (formdata.password === formdata.confirmpass) {
        const error = await dispatch(signUp(formdata, navigate));
        if (error) {
          setErrorMessage(error); // Set error message
        }
      } else {
        setConfirmPass(false);
      }
    } else {
      dispatch(logIn(formdata, navigate));
    }
  };

  return (
    <div className="Auth">
      {/* left side */}
      <div className="a-left">
        <img src={Logo} alt="" />
        <div className="Webname">
          <h1>LAB-MED</h1>
          <h6>Connecting Medictians and Doctors</h6>
        </div>
      </div>

      {/* right form side */}
      <div className="a-right">
        <form className="infoForm authForm" onSubmit={handleSubmit}>
          <h3>{isSignUp ? "Register as a Student" : "Login"}</h3>
          {isSignUp && (
            <div>
              <input
                required
                type="text"
                placeholder="First Name"
                className="infoInput"
                name="firstname"
                value={formdata.firstname}
                onChange={handleChange}
              />
              <input
                required
                type="text"
                placeholder="Last Name"
                className="infoInput"
                name="lastname"
                value={formdata.lastname}
                onChange={handleChange}
              />
            </div>
          )}

          <div>
            <input
              required
              type="text"
              placeholder="Username"
              className="infoInput"
              name="username"
              value={formdata.username}
              onChange={handleChange}
            />
            {isSignUp && (
              <input
                required
                type="number"
                placeholder="Phone Number"
                className="infoInput"
                name="phone"
                value={formdata.phone}
                onChange={handleChange}
              />
            )}
          </div>
          {isSignUp && (
            <div>
              <input
                required
                type="text"
                placeholder="university"
                className="infoInput"
                name="university"
                value={formdata.university}
                onChange={handleChange}
              />
            </div>
          )}


          {isSignUp && (
              <Select
                isMulti
                name="interests"
                options={interestOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="select interests"
                onChange={handleInterestsChange}
                value={interestOptions.filter(option => formdata.interests.includes(option.value))}
              />
          )}

          {isSignUp && (
            <div>
              <input
                type="text"
                placeholder="Promo Code"
                className="infoInput"
                name="codePromo"
                value={formdata.codePromo}
                onChange={handleChange}
              />
            </div>
          )}


          {isSignUp && (
            <div>
              <input
                required
                type="number"
                placeholder="Year"
                className="infoInput"
                name="year"
                value={formdata.year}
                onChange={handleChange}
              />
            </div>
          )}
          {!previewUrl && isSignUp && <div className="createPost">
            
            <div className="mx-auto max-w-xs">
              <label for="profilePicture" className="mb-1 block text-sm font-medium text-gray-700">Upload file</label>
              <input id="profilePicture" onChange={handleFileInputChange} name="profilePicture" type="file" className="block w-full text-sm file:mr-4 file:rounded-md file:border-0 file:bg-primary-500 file:py-2.5 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-primary-700 focus:outline-none disabled:pointer-events-none disabled:opacity-60" />
            </div>
            
                        </div>}
                        {previewUrl && isSignUp && <>
                        <div className="avatar">
                          <div className="h-10 w-10">
                <img className="h-full w-full rounded-full object-cover object-center" src={previewUrl} alt="" />
              </div></div>
                        </>}

          <div>
            <input
              required
              type="password"
              className="infoInput"
              placeholder="Password"
              name="password"
              value={formdata.password}
              onChange={handleChange}
            />
            {isSignUp && (
              <input
                required
                type="password"
                className="infoInput"
                name="confirmpass"
                placeholder="Confirm Password"
                value={formdata.confirmpass}
                onChange={handleChange}
              />
            )}
          </div>

          <span
            style={{
              color: "red",
              fontSize: "12px",
              alignSelf: "flex-end",
              marginRight: "5px",
              display: confirmPass ? "none" : "block",
            }}
          >
            *Confirm password is not the same
          </span>
          {errorMessage && ( // Display error message
            <span
              style={{
                color: "red",
                fontSize: "12px",
                alignSelf: "flex-end",
                marginRight: "5px",
                display: "block",
              }}
            >
              *{errorMessage}
            </span>
          )}

          <div>
            <span
              style={{
                fontSize: "12px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                resetForm();
                setIsSignUp((prev) => !prev);
              }}
            >
              {isSignUp
                ? "Already have an account? Login"
                : "Don't have an account? Sign up"}
            </span>
            <button
              className="button infoButton"
              type="Submit"
              disabled={loading}
            >
              {loading ? "Loading..." : isSignUp ? "Sign Up" : "Login"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

