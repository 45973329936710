import React from "react";
import Heading from "../../components/common/heading/Heading";
import "./style.css";
import val from "../../img/logo.png";

const Testimonal = () => {
  return (
    <>
      <section className='testimonal padding'>
        <div className='container'>
          <Heading subtitle='SignUp' title='You Are ...' />
          <div className="flex">
            <div className='content grid2'>
              <a href="/medStudent">
                <div className='items shadow'>
                  <div className='box flex'>
                    <div className='img'>
                      <img src={val} alt='' />
                    </div>
                    <div className='name'>
                      <h2>Medical Student</h2>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className='content grid2'>
              <a href="/Doctor">
                <div className='items shadow'>
                  <div className='box flex'>
                    <div className='img'>
                      <img src={val} alt='' />
                    </div>
                    <div className='name'>
                      <h2>Doctor / pharmacist</h2>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className='content grid2'>
              <a href="/Laboratory">
                <div className='items shadow'>
                  <div className='box flex'>
                    <div className='img'>
                      <img src={val} alt='' />
                    </div>
                    <div className='name'>
                      <h2>Laboratory</h2>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonal;
