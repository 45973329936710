import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadImage, uploadSlide } from "../actions/UploadAction";
import { deleteSlide, getSlides } from "../api/SlidesRequest";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./newbooks.css";
import Swal from "sweetalert2";

function New() {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [Slides, setSlides] = useState([]);
  const [link, setLink] = useState("");

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const { data } = await getSlides();
        setSlides(data.Slides);
      } catch (error) {
        console.error("Error fetching slides:", error);
      }
    };
    fetchSlides();
  }, []);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!image) return;

    const formData = new FormData();
    const fileName = Date.now() + image.name;
    formData.append("name", fileName);
    formData.append("file", image);

    try {
      await dispatch(uploadImage(formData));
      await dispatch(uploadSlide({ image: fileName, link }));

      // Fetch updated slides after adding a new slide
      const { data } = await getSlides();
      setSlides(data.Slides);

      resetShare();
    } catch (error) {
      console.error("Error uploading slide:", error);
    }
  };

  const resetShare = () => {
    setImage(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleDelete = (slide) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteSlide(slide._id); // Make API call to delete the slide
          setSlides((prevSlides) =>
            prevSlides.filter((s) => s._id !== slide._id)
          ); // Update state after deletion
          Swal.fire({
            title: "Deleted!",
            text: "Your slide has been deleted.",
            icon: "success",
          });
        } catch (error) {
          console.error("Error deleting the slide:", error);
        }
      }
    });
  };

  return (
    <div id="New" className="w-full mx-auto px-4 py-6">
      <Slider className="mx-auto w-[25rem] md:w-[20rem] lg:w-[35rem] xl:w-[50rem]" {...settings}>
  {Slides.map((slide) => (
    <div key={slide._id} className="card">
      <div className="card-top">
        <a href={slide.link} target="_blank" rel="noopener noreferrer">
          <img
            src={process.env.REACT_APP_PUBLIC_FOLDER + slide.image}
            alt="slide"
          />
        </a>
      </div>
    </div>
  ))}
</Slider>


      {!image && (
        <div className="mx-auto my-4 text-center">
          <label
            htmlFor="example5"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Upload a new Slide
          </label>
          <label className="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 border-dashed border-gray-200 p-6 transition-all hover:border-primary-300">
            <div className="space-y-1 text-center">
              <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>
              </div>
              <div className="text-gray-600">
                <span className="font-medium text-primary-500 hover:text-primary-700 cursor-pointer">
                  Click to upload
                </span>{" "}
                or drag and drop
              </div>
              <p className="text-sm text-gray-500">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </div>
            <input
              id="example5"
              type="file"
              className="sr-only"
              onChange={onImageChange}
            />
          </label>
        </div>
      )}

      {image && (
        <div className="mx-auto my-4 text-center">
          <img
            src={URL.createObjectURL(image)}
            alt="Selected"
            className="mx-auto max-w-xs rounded-lg shadow-lg"
          />
        </div>
      )}

      <div className="mx-auto my-4 text-center">
        <label
          htmlFor="link"
          className="mb-1 block text-sm font-medium text-gray-700"
        >
          Link (Optional)
        </label>
        <input
          id="link"
          type="text"
          value={link}
          onChange={handleLinkChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          placeholder="https://example.com"
        />
      </div>

      <button
        onClick={handleSubmit}
        className="mt-4 mx-auto block rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none"
      >
        Submit
      </button>

      <p>Delete Slides</p>

      {Slides.map((slide, index) => (
        <tbody
          key={slide._id}
          className="divide-y divide-gray-100 border-t border-gray-100"
        >
          <tr>
            <th className="px-6 py-4 font-medium text-gray-900">{index + 1}</th>
            <td className="px-6 py-4 font-semibold truncate max-w-xs">
              <img
                className="h-10 w-20 rounded-sm object-cover object-center"
                src={process.env.REACT_APP_PUBLIC_FOLDER + slide.image}
                alt=""
              />
            </td>
            <td className="flex justify-end gap-4 px-6 py-4 font-medium">
              <div
                onClick={() => handleDelete(slide)}
                className="text-red-600 cursor-pointer font-bold"
              >
                Delete
              </div>
            </td>
          </tr>
        </tbody>
      ))}
    </div>
  );
}

export default New;
