import axios from "axios";

const API = axios.create({ baseURL: "https://labmeddz.com/api" });

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
  
    return req;
});

export const getUser = (userId) => API.get(`/user/${userId}`);
export const updateUser = (id, formData) =>  API.put(`/user/${id}`, formData);
export const getAllUser = () => API.get('/user');
export const getAllDoctors = () => API.get('/user/user/doctors');
export const getAllLaboratories = () => API.get('/user/user/laboratories'); // New request for fetching all laboratories
export const getDoctorsAndStudentsByRating = () => API.get('/user/user/doctors-and-students-by-rating'); // New request for fetching all laboratories
export const getAllStudents = () => API.get('/user/user/students'); // New request for fetching all students
export const followUser = (id, data) => API.put(`/user/${id}/follow`, data);
export const unfollowUser = (id, data) => API.put(`/user/${id}/unfollow`, data);
