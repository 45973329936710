import React from "react"
import Back from "../common/back/Back"
import "./contact.css"
import { useForm, ValidationError } from "@formspree/react";

const Contact = () => {
  const [state, handleSubmit] = useForm("xzbnyzvo");

  
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103906.4286739633!2d6.42305622012513!3d35.52712208960096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12f6a6ca2d34e3c7%3A0x9bb53786e271bb3d!2sTimgad%2C%20Algeria!5e0!3m2!1sen!2sus!4v1717766686341!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" '
  return (
    <>
      <Back title='Contact us' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>
          <div className='right row'>
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>ADDRESS:</h4>
                <p>Timgad , Batna</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p> info@labmeddz.com</p>
              </div>
              <div className='box'>
                <h4>PHONE:</h4>
                <p>0772474809</p>
              </div>
            </div>

            <form onSubmit={handleSubmit} action='#'>
              <div className='flexSB'>
                <input 
                id="name" type='text' placeholder='Name' />
                <ValidationError
                        prefix="name"
                        field="name"
                        errors={state.errors}
                      />
                <input name="email"
                type="email"
                id="email"  placeholder='Email' />
                <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                      />
              </div>
              <input type="text"
                id="subject"
                name="subject" placeholder='Subject' /> <ValidationError
                prefix="subject"
                field="subject"
                errors={state.errors}
              />
              <textarea name="message"
              id="message" cols='30' rows='10'>
                Create a message here...
              </textarea>
              <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                      />
              <button disabled={state.submitting}
              type="submit" className='primary-btn'>SEND MESSAGE</button>
              {state.succeeded && (
            <p
              className="flex"
              style={{ fontSize: "18px", marginTop: "1.7rem" }}
            >
              
              Your message has been sent successfully 👌
            </p>
          )}
            </form>

            <h3>Follow us here</h3>
            <span>FACEBOOK TWITTER INSTAGRAM DRIBBBLE</span>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
