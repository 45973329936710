import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  uploadImage } from "../../actions/UploadAction";
import { getPost } from "../../api/PostsRequests";
import "./edit.css";
import { updatePost } from "../../api/UploadRequest";
const POST_CATEGORIES = [
  "Internal Medicine",
  "Pediatrics",
  "Obstetrics and Gynecology",
  "Surgery",
  "Psychiatry",
  "Anesthesiology"
];



// or via CommonJS

function EditPost() {
  const { id } = useParams(); // Extracting the ID from the URL params
  const [post, setPost] = useState(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const { data } = await getPost(id); // Fetch post using the extracted ID
        setPost(data);
        setTitle(data.title);
        setDesc(data.desc);
        setCategory(data.category);
      } catch (error) {
        console.error("Error fetching the post:", error);
      }
    };

    fetchPost();
  }, [id]);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer.authData);
  const loading = useSelector((state) => state.postReducer.uploading);
  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Post data
    const updatedPost = {
      userId: user?._id,
      Username: user?.username,
      ProfilePicture: user?.profilePicture,
      title,
      desc,
      category,
    };
  
    // If there is an image with post
    if (image) {
      const data = new FormData();
      const fileName = Date.now() + image.name;
      data.append("name", fileName);
      data.append("file", image);
      updatedPost.image = fileName;
      try {
        await dispatch(uploadImage(data));
      } catch (err) {
        console.log(err);
      }
    }
  
    try {
      await updatePost(id, updatedPost);
      
      // Assuming updatePost returns updated data, update state immediately
      setPost(updatedPost); // Update local state with new post data
      
      // Optionally, you can redirect after successful update
      navigate("/home");
    } catch (error) {
      console.error("Error updating the post:", error);
    }
  };
  

  // Handle Image Change
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImage(img);
    }
  };

  



  if (!post) return <div>Loading...</div>;

  return (
    <>
      <div className="createPost">
        <form onSubmit={handleSubmit} className="mx-auto mt-48 container">
          <div className="mx-auto my-4">
            <div>
              <label htmlFor="title" className="mb-1 block text-lg font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full p-3 border rounded-md border-secondaryColor border-solid shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                placeholder="Article Title"
              />
            </div>
          </div>
          <div className="mx-auto my-4">
            <div>
              <label htmlFor="category" className="mb-1 block text-lg font-medium text-gray-700">
                Select Category
              </label>
              <select
                id="category"
                name="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="p-4 outline-blue-800 block w-full border rounded-xl border-secondaryColor"
              >
                {POST_CATEGORIES.map((cat, index) => (
                  <option key={index} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mx-auto my-4">
            <div className="mx-auto ">
              <label htmlFor="example5" className="mb-1 block text-sm font-medium text-gray-700">
                Upload file
              </label>
              <label className="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 border-dashed border-gray-200 p-6 transition-all hover:border-primary-300">
                <div className="space-y-1 text-center">
                  <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </div>
                  <div className="text-gray-600">
                    <a href="/" className="font-medium text-primary-500 hover:text-primary-700">
                      Click to upload
                    </a>{" "}
                    or drag and drop
                  </div>
                  <p className="text-sm text-gray-500">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                </div>
                <input id="example5" type="file" className="sr-only" onChange={onImageChange} />
              </label>
            </div>
          </div>
          <div className="mx-auto my-4">
            <ReactQuill value={desc} onChange={setDesc} name="description" id="description" className="" />
          </div>
          <div className="mx-auto my-4">
            <button
              className="inline-block w-full my-4 rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
              type="submit"
              disabled={loading}
            >
              {loading ? "Uploading" : "Save Post"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditPost;
