import React, { useEffect, useState } from 'react';
import styles from '../../styles/styles';
import { getCartItems, removeFromCart } from '../../actions/CartAction';
import { useDispatch } from 'react-redux';
import { FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { uploadOrder } from '../../actions/UploadAction';
import { useNavigate } from 'react-router-dom';

const Checkout = ({ handleAddStep, activeStep }) => {
  const [cart, setCart] = useState([]);
  const [formFields, setFormFields] = useState({
    fullName: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    address1: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cartItems = await dispatch(getCartItems());
        setCart(cartItems);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCartItems();
  }, [dispatch]);

  const handleRemoveItem = async (id) => {
    try {
      await dispatch(removeFromCart(id));
      setCart(cart.filter((item) => item._id !== id));
    } catch (error) {
      console.error('Error removing cart item:', error);
    }
  };

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.originalPrice,
    0
  );

  const shipping = subTotalPrice * 0.1;
  const totalPrice = (subTotalPrice + shipping).toFixed(2);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const isFormValid = () => {
    return Object.values(formFields).every(field => field.trim() !== '');
  };

  const clearCart = () => {
    // Clear cart items from localStorage
    localStorage.removeItem('cartItems');
    // Clear cart state
    setCart([]);
  };
  
  



  const handleAddStepWithValidation = () => {
    if (isFormValid()) {
      const newOrder = {
        formFields,
        totalPrice,
        cart,
      };

  
      dispatch(uploadOrder(newOrder));
      handleAddStep();
      clearCart()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: 'Please enter All your Information'
      });
    }
  };


  return (
    <div className="w-full flex flex-col items-center py-8">
      {activeStep === 1 ? (
        cart.length > 0 ? (
          <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
            <div className="w-full 800px:w-[65%]">
              <ShippingInfo formFields={formFields} handleInputChange={handleInputChange} />
            </div>
            <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
              <CartData
                cart={cart}
                totalPrice={totalPrice}
                shipping={shipping}
                subTotalPrice={subTotalPrice}
                handleRemoveItem={handleRemoveItem}
              />
            </div>
          </div>
        ) : (
          <div><h1>Your Cart Is empty</h1></div>
        )
      ) : activeStep === 2 ? (
        <div className=' p-3 text-center flex flex-col gap-4 items-center'><h1>Your Product has been Added Succefully Thank for buying !</h1>
       </div>
      ) : null}
      
      {cart.length > 0 && activeStep === 1 && (
        <div onClick={handleAddStepWithValidation} className={`${styles.button} w-[150px] bg-blue-800 hover:bg-blue-500 transition-colors 800px:w-[280px] mt-10`}>
          <h5 className="text-white">
            Checkout
          </h5>
        </div>
      )}
      
      {!cart.length > 0 && (
        <a href='/products' className={`${styles.button} w-[150px] bg-blue-800 hover:bg-blue-500 transition-colors 800px:w-[280px] mt-10`}>
          <h5 className="text-white">
            Go to Shop
          </h5>
        </a>
      )}
    </div>
  );
};

const ShippingInfo = ({ formFields, handleInputChange }) => {
  return (
    <div className="w-full 800px:w-[95%] bg-white rounded-md p-5 pb-8">
      <h5 className="text-[18px] font-[500]">Shipping Address</h5>
      <br />
      <form>
        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2">Full Name</label>
            <input
              type="text"
              name="fullName"
              value={formFields.fullName}
              onChange={handleInputChange}
              required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">Email Address</label>
            <input
              type="email"
              name="email"
              value={formFields.email}
              onChange={handleInputChange}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>

        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2">Phone Number</label>
            <input
              type="number"
              name="phone"
              value={formFields.phone}
              onChange={handleInputChange}
              required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">Address1</label>
            <input
              type="text"
              name="address1"
              value={formFields.address1}
              onChange={handleInputChange}
              required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
        </div>

        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2">Country</label>
            <input
              type="text"
              name="country"
              value={formFields.country}
              onChange={handleInputChange}
              required
              className={`${styles.input} !w-[95%]`}
            />
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">City</label>
            <input
              type="text"
              name="city"
              value={formFields.city}
              onChange={handleInputChange}
              required
              className={`${styles.input}`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const CartData = ({ cart, totalPrice, shipping, subTotalPrice, handleRemoveItem }) => {
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <h3 className="text-[18px] font-[500] mb-4">Cart Summary</h3>
      <table className="w-full text-left mb-4">
        <thead>
          <tr>
            <th className="border-b p-2">Image</th>
            <th className="border-b p-2">Product Name</th>
            <th className="border-b p-2">Price</th>
            <th className="border-b p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {cart?.map((item) => (
            <tr key={item._id}>
              <td className="p-2">
                <img
                  src={process.env.REACT_APP_PUBLIC_FOLDER + item.images}
                  alt={item.name}
                  className="w-16 h-16 rounded-md object-cover"
                />
              </td>
              <td className="p-2">{item.name}</td>
              <td className="p-2">{item.originalPrice} DA</td>
              <td className="p-2">
                <div onClick={() => handleRemoveItem(item._id)} className="text-red-600 cursor-pointer">
                  <FaTrash />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Subtotal:</h3>
        <h5 className="text-[18px] font-[600]">{subTotalPrice} Da</h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Shipping:</h3>
        <h5 className="text-[18px] font-[600]">{shipping.toFixed(2)} Da</h5>
      </div>
      <br />
      <div className="flex justify-between pt-3 items-center">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Total:</h3>
        <h5 className="text-[18px] font-[600] text-end">{totalPrice} Da</h5>
      </div>
    </div>
  );
};

export default Checkout;
