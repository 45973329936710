import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addTocart } from '../../actions/CartAction';
import "./style.css";
import Back from '../../components/common/back/Back';
import { getProduct } from '../../api/ProductsRequest';
import Swal from 'sweetalert2';

function Post() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [amount, setAmount] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const { data } = await getProduct(id);
        setPost(data);
      } catch (error) {
        console.error("Error fetching the post:", error);
      }
    };

    fetchPost();
  }, [id]);

  const handleMinus = () => {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  };

  const addToCartHandler = () => {
    if (post) {
      const { name, images, _id, originalPrice } = post;
      dispatch(addTocart({
        name,
        images,
        _id,
        originalPrice,
        amount,
      }));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Porduct Added to cart",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  if (!post) return <div>Loading...</div>;

  return (
    <>
      <Back title={post?.name} />
      <div className=' mx-auto container md:mt-20 lg:mt-36'>
        <div className="grid md:grid-cols-2 gap-6 lg:gap-12 items-center max-w-6xl px-4 mx-auto py-6">
          <div className="grid gap-6 md:gap-3 items-start order-2">
            <img
              src={process.env.REACT_APP_PUBLIC_FOLDER + post?.images}
              alt="post"
              width="600"
              height="600"
              className="aspect-square object-contain border border-zinc-200 w-full rounded-lg overflow-hidden dark:border-zinc-800"
            />
          </div>
          <div className="grid gap-4 md:gap-10 items-start order-2 md:order-1">
            <h1 className="font-bold lg:text-5xl md:text-4xl text-3xl xl:text-6xl">{post?.name}</h1>
            <div className="flex items-center gap-4">
              {/* SVG icons */}
            </div>
            <form className="grid gap-4 md:gap-8">
              <div className="text-4xl font-bold"> {post?.originalPrice} DA</div>
              <button
                type="button"
                className="inline-flex bg-blue-800 text-white text-base-100 items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-11 rounded-md px-8"
                onClick={addToCartHandler}
              >
                Add to cart
              </button>
            </form>
          </div>
          
        </div>
        <div className=' container mx-auto my-4'>
          <h1 className=' underline'>Product Descirption :</h1>
        <p className="text-sm md:text-base p-5 lg:text-lg leading-loose">
              {post?.description}
            </p></div>
      </div>
    </>
  );
}

export default Post;
