import React, { useState } from "react";
import "./RightSide.css";

import TrendCard from "../TrendCard/TrendCard";
import BestUsers from "../BestUsers/BestUsers";
import ShareModal from "../ShareModal/ShareModal";
import NavIcons from "../NavIcons/NavIcons";
const RightSide = () => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div className="RightSide">
      {/* Side Navbar */}

      <NavIcons />
      {/* TrendCard */}
      <TrendCard />


      {/* Share buttong */}
      <a href="/create-Article">
      <button className="button r-button" >
        Share an Article
      </button>
      </a>
      <ShareModal modalOpened={modalOpened} setModalOpened={setModalOpened} />
      <BestUsers/>
    </div>
  );
};

export default RightSide;
