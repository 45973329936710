import React, { useState } from "react";
import "./Post.css";
import Comment from "../../img/comment.png";
import Heart from "../../img/like.png";
import NotLike from "../../img/notlike.png";
import { likePost } from "../../api/PostsRequests";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import { MdReadMore } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Swal from 'sweetalert2'
import { deletedPost } from "../../api/UploadRequest";


const Post = ({ data, onDelete }) => {
  const extractPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const { user } = useSelector((state) => state.authReducer.authData);
  const [liked, setLiked] = useState(data.likes?.includes(user._id));
  const [likes, setLikes] = useState(data.likes?.length);
  const dispatch = useDispatch();

  const handleLike = () => {
    likePost(data._id, user._id);
    setLiked((prev) => !prev);
    liked ? setLikes((prev) => prev - 1) : setLikes((prev) => prev + 1);
  };

  // Function to format createdAt date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const deletePost = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deletedPost(data._id, { userId: user._id });
          // Update the UI by notifying the parent component to remove this post from the list
          onDelete(data._id);
          Swal.fire({
            title: "Deleted!",
            text: "Your Article has been deleted.",
            icon: "success"
          });
        } catch (error) {
          console.error("Error deleting the post:", error);
        }
      }
    });
  };

  return (
    <div className="Post">
      <div className="postContent">
        <img
          className="postImage"
          src={
            data.image ? process.env.REACT_APP_PUBLIC_FOLDER + data.image : ""
          }
          alt=""
        />
        <div className="detail">
          <span>
            <div className="titlehref">
              <b className="title">{data.title} </b>
              <div>
                {user._id === data.userId && <a className="edit" href={`/editArticle/${data._id}`}><MdEdit /></a>}
                {user._id === data.userId && <span style={{ cursor: "pointer" }} onClick={deletePost} className="deletebutton"><MdDelete /></span>}
              </div>
            </div>
          </span>
          <span className="postDescription">
            {extractPlainText(
              data.desc?.length > 300
                ? data.desc.substr(0, 300) + "..."
                : data?.desc
            )}
          </span>
        </div>
      </div>

      <div className="postReact">
        <img
          src={liked ? Heart : NotLike}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={handleLike}
        />
        <img style={{ cursor: "pointer" }} src={Comment} alt="" />
        <a className="titlehref readMore" href={`/Articles/${data._id}`}>
          <MdReadMore />
        </a>
        <div className="rounded-lg border  border-violet-500 bg-violet-500 px-5 py-2 text-center text-sm font-medium text-white shadow-sm transition-all hover:border-violet-700 hover:bg-violet-700 focus:ring focus:ring-violet-200 disabled:cursor-not-allowed disabled:border-violet-300 disabled:bg-violet-300">
          {data.category}
        </div>
      </div>

      <div>
        <span style={{ color: "var(--gray)", fontSize: "12px" }}>
          {likes} likes
        </span>
        <span
          style={{
            fontSize: "12px",
            marginLeft: "3px",
            fontWeight: "bold",
            textDecoration: "underline",
            color: "black"
          }}

        >
          <a style={{
            color: "black"
          }} href={`/profile/${data.userId}`}>By {data.Username}</a> / At {formatDate(data.createdAt)}
        </span>
      </div>
    </div>
  );
};

export default Post;
