export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "pharmaceutical companies",
    desc:`Reach a broader  audience of physicians and pharmacists.
    Enhance operational efficiency and increase profitability.
    Foster strong relationships with physicians and pharmacists.
    `,
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Doctors and pharmacists",
    desc: `Access the latest information on medications and advancements in the field.
    Effectively communicate with pharmaceutical and medical supplies companies.
    `,
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Medical Students",
    desc: `Information  about job opportunities in the pharmaceutical sales representative field.
    Opportunities on how to promote medications effectively.
    
    `,
  },
]

export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Introducing to Software Engineering",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by John Smith",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Enhancing Adobe Photoshop CC 2020 Skills",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Ram Gurung",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "HTML, CSS, and Javascript for Web Developers",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saroj Nepal",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
]
export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  {
    cover: "./images/team/Farouk.webp",
    name: "Farouk Benzina",
    work: "Founder and CEO",
  },
  {
    cover: "./images/team/Aymen.webp",
    name: "Aymen Banchouri",
    work: "CO-FOUNDER and Developer",
  },

]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49000 Da",
    desc: "Ideal for small-scale laboratories, this plan includes basic features such as sample management, result tracking, and standard reporting tools to streamline your operations.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79000 Da",
    desc: "Perfect for growing laboratories, the Beginner Plan offers advanced features like detailed analytics, priority customer support, and integration with medical databases to enhance your lab's efficiency.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109000 Da",
    desc: "Our Premium Plan is designed for large laboratories that require comprehensive solutions. It includes all features from the Basic and Beginner plans, plus customized workflows, automated compliance checks, and unlimited user access.",
  },
]

export const faq = [
  {
    title: "What is LABMED?",
    desc: "The 'Lab-Med' platform offers a revolutionary solution that directly connects pharmaceutical laboratories, doctors (physicians and pharmacists), and medical students.",
  },
  {
    title: "What makes us unique?",
    desc: "LABMED stands out due to its comprehensive integration of pharmaceutical laboratories with healthcare professionals and medical students. This unique platform facilitates seamless communication, collaboration, and access to a vast repository of medical knowledge and resources.",
  },
  {
    title: "Does LABMED offer any discounts or promotions for events by points earning?",
    desc: "Yes, LABMED offers various discounts and promotions that can be earned through our points system. You can accumulate points by participating in events, completing educational modules, and engaging with the platform. These points can be redeemed for discounts on future events and other benefits.",
  },
  {
    title: "How does the points system work?",
    desc: "The points system is designed to reward active participation on the LABMED platform. Users earn points by attending events, participating in discussions, completing educational content, and more. Accumulated points can be redeemed for discounts, access to exclusive events, and other rewards.",
  },
  {
    title: "Are there any membership fees for using LABMED?",
    desc: "No, LABMED does not charge any membership fees. The platform is free to use for all registered healthcare professionals and medical students. However, certain premium features and events may require payment or points redemption.",
  },
  {
    title: "How can I contact LABMED?",
    desc: "You can contact us by sending an email to support@LABMED.com or by phone at +00000000.",
  },
];

export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonial = [
  {
    id: 1,
    name: "Ahmed",
    post: "Medical Student",
    desc: "LabMed has revolutionized the way I connect with laboratories. As a medical student, having access to a reliable network of labs has significantly enhanced my learning experience and research capabilities.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "Mourad",
    post: "Doctor",
    desc: "LabMed is an incredible platform that bridges the gap between doctors and laboratories. It streamlines the process of ordering tests and receiving results, making my workflow more efficient and allowing me to provide better care to my patients.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "Younes",
    post: "CEO of a Medical Laboratory",
    desc: "LabMed has been a game-changer for our laboratory. It has expanded our reach and connected us with a network of doctors who trust our services. The platform is user-friendly and has significantly boosted our productivity and growth.",
    cover: "./images/testo/t3.webp",
  },
]

