import React, { useEffect, useState } from "react";
import "./style.css";
import Logo from "../../img/logo.png";
import { getAllUser } from "../../api/UserRequests";
import { getAllOrders, getAllProducts } from "../../api/ProductsRequest";
import { getAdminPosts } from "../../api/PostsRequests";
import { useSelector } from "react-redux";
import SlidesDash from "../../components/Slides.jsx";

function Dashboard() {
  const { user } = useSelector((state) => state.authReducer.authData);
  const [persons, setPersons] = useState([]);
  const [order, setOrder] = useState([]);
  const [Products, setProducts] = useState([]);
  const [Posts, setPosts] = useState([]);
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };
  const today = new Date().toISOString().substr(0, 10);

  useEffect(() => {
    const fetchPersons = async () => {
      const { data } = await getAllUser();
      setPersons(data);
    };
    fetchPersons();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await getAllProducts();
      setProducts(data.products);
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      const { data } = await getAdminPosts();
      setPosts(data.posts);
    };
    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      const { data } = await getAllOrders();
      setOrder(data.orders);
    };
    fetchOrders();
  }, []);

  const [activeTab, setActiveTab] = useState("dashboard");

  console.log(order);

  return (
    <div className="Dashboardcontainer">
      <aside style={{ display: isMenuVisible ? "block" : "" }}>
        <div className="top">
          <div className="logo">
            <a href="/home">
              <img src={Logo} alt="" />
            </a>
          </div>
          <div onClick={toggleMenu} className="close" id="close_btn">
            <span className="material-symbols-sharp">close</span>
          </div>
        </div>
        {/* end top */}
        <div className="sidebar">
          <button
            href="/"
            className={activeTab === "dashboard" ? "active" : ""}
            onClick={() => setActiveTab("dashboard")}
          >
            <span className="material-symbols-sharp">grid_view</span>
            <h3>Dashboard</h3>
          </button>
          <button
            href="/"
            className={activeTab === "slides" ? "active" : ""}
            onClick={() => setActiveTab("slides")}
          >
            <span className="material-symbols-sharp">person_outline</span>
            <h3>Ads Slides</h3>
          </button>
          <button href="/">
            <span className="material-symbols-sharp">insights</span>
            <h3>Analytics</h3>
          </button>
          <button href="/">
            <span className="material-symbols-sharp">receipt_long</span>
            <h3>Products</h3>
          </button>
          <button href="/">
            <span className="material-symbols-sharp">report_gmailerrorred</span>
            <h3>Reports</h3>
          </button>
          <button href="/">
            <span className="material-symbols-sharp">settings</span>
            <h3>Settings</h3>
          </button>
          <button href="/add-product">
            <span className="material-symbols-sharp">add</span>
            <h3>Add Product</h3>
          </button>
          <button href="/">
            <span className="material-symbols-sharp">logout</span>
            <h3>Logout</h3>
          </button>
        </div>
      </aside>
      {/* end aside */}

      <main>
        {activeTab === "dashboard" && (
          <>
            <h1>Dashboard</h1>
            <div className="date">
              <input type="date" defaultValue={today} />
            </div>
            <div className="insights">
              <div className="sales">
                <span className="material-symbols-sharp">trending_up</span>
                <div className="middle">
                  <div className="left">
                    <h3>Total Users</h3>
                    <h1>{persons?.length}</h1>
                  </div>
                  <div className="progress">
                    <svg>
                      <circle r="30" cy="40" cx="40"></circle>
                    </svg>
                    <div className="number">
                      <p>100%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="expenses">
                <span className="material-symbols-sharp">local_mall</span>
                <div className="middle">
                  <div className="left">
                    <h3>Total Products</h3>
                    <h1>{Products?.length}</h1>
                  </div>
                  <div className="progress">
                    <svg>
                      <circle r="30" cy="40" cx="40"></circle>
                    </svg>
                    <div className="number">
                      <p>60%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="income">
                <span className="material-symbols-sharp">
                  stacked_line_chart
                </span>
                <div className="middle">
                  <div className="left">
                    <h3>Total Posts</h3>
                    <h1>{Posts?.length}</h1>
                  </div>
                  <div className="progress">
                    <svg>
                      <circle r="30" cy="40" cx="40"></circle>
                    </svg>
                    <div className="number">
                      <p>50%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent_order">
              <h2>Recent Orders</h2>
              <table>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Product Total Price</th>
                    <th>Payments</th>
                    <th>Status</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {order.map((order) => (
                    <tr key={order._id}>
                      <td>
                        
                          {order.cart.map((cart, index) => (
                            <p key={index}>{cart.name}</p>
                          ))}
                        
                      </td>
                      <td>{order.totalPrice} Da</td>
                      <td>Due</td>
                      <td className="warning">{order.status}</td>
                      <td className="primary">Details</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <a href="/">Show All</a>
            </div>
          </>
        )}
        {activeTab === "slides" && <Slides />}
      </main>

      {/* end main */}

      <div className="right">
        <div className="top">
          <button onClick={toggleMenu} id="menu_btn">
            <span className="material-symbols-sharp">menu</span>
          </button>

          <div className="profile">
            <div className="info">
              <p>
                <b>{user?.username}</b>
              </p>
              <p>Admin</p>
            </div>
            <div className="profile-photo">
              <img src={user?.profilePicture} alt="Profile" />
            </div>
          </div>
        </div>

        <div className="recent_updates">
          <h2>Recent Posts</h2>

          <div className="updates">
            {Posts.slice(0, 3).map((post) => (
              <div key={post?._id} className="update">
                <div className="profile-photo">
                  <img
                    src={process.env.REACT_APP_PUBLIC_FOLDER + post?.image}
                    alt="Profile"
                  />
                </div>
                <div className="messageDashboard">
                  <p>
                    <b>{post?.Username}</b> {post?.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="sales-analytics">
          <h2>Sales Analytics</h2>

          <div className="item offline">
            <div className="icon">
              <span className="material-symbols-sharp">storefront</span>
            </div>
            <div className="right_text">
              <div className="info">
                <h3>Offline Orders</h3>
                <small className="text-muted">Last seen 4 Hours ago</small>
              </div>
              <h5 className="success">+5%</h5>
              <h3>2120</h3>J
            </div>
          </div>
          <div className="item customers">
            <div className="icon">
              <span className="material-symbols-sharp">people_alt</span>
            </div>
            <div className="right_text">
              <div className="info">
                <h3>New Customers</h3>
                <small className="text-muted">Last seen 2 Hours ago</small>
              </div>
              <h5 className="success">+10%</h5>
              <h3>150</h3>
            </div>
          </div>
        </div>

        <div className="item add_product">
          <div>
            <span className="material-symbols-sharp">add</span>
          </div>
        </div>
      </div>
      {/* end right */}
    </div>
  );
}

export default Dashboard;

function Slides() {
  return (
    <>
      <div className=" margini">
        <h1 className="">Slides</h1>
        <p>Here you can manage your advertisement slides.</p>
        {/* Add more content related to slides management here */}
        <SlidesDash />
      </div>
    </>
  );
}
