import React, { useEffect, useState } from "react";
import "./FollowersCard.css";
import DoctorAvatar from "../../img/doctorAvatar.jpg"
import LaboratoryAvatar from "../../img/LaboratoryAvatar.png"

{/*import FollowersModal from "../FollowersModal/FollowersModal";
import { getAllUser } from "../../api/UserRequests";
import User from "../User/User";
import { useSelector } from "react-redux";*/}
const FollowersCard = ({ location }) => {


{/*  const [modalOpened, setModalOpened] = useState(false);
  const [persons, setPersons] = useState([]);
  const { user } = useSelector((state) => state.authReducer.authData);

  useEffect(() => {
    const fetchPersons = async () => {
      const { data } = await getAllUser();
      setPersons(data);
    };
    fetchPersons();
  }, []);*/}

  return (
<div className="FollowersCard">
  <h3 className="mb-1">Search for Doctors or Laboratories</h3>
  
  <div className="mx-auto max-w-md overflow-hidden rounded-lg bg-white shadow">
    <ul className="divide-y  divide-gray-100 py-2 px-4">
      <a href="/Doctors">
      <li className="flex items-center mb-1 py-4">
        <div className="mr-4 flex-1">
          <h4 className="text-lg font-medium text-gray-900">Doctors</h4>
        </div>
        <div>
          <img src={DoctorAvatar} className="h-10 w-10 rounded-lg object-cover" alt="Doctors" />
        </div>
      </li></a>
      <a href="/Laboratories">
      <li className="flex items-center py-4">
        <div className="mr-4 flex-1">
          <h4 className="text-lg font-medium text-gray-900">Laboratories</h4>
        </div>
        <div>
          <img src={LaboratoryAvatar} className="h-10 w-10 rounded-lg object-cover" alt="Laboratories" />
        </div>
      </li></a>
    </ul>
  </div>



      {/*{persons.map((person, id) => {
        if (person._id !== user._id) return <User person={person} key={id} />;
      })}
      {!location ? (
        <span onClick={() => setModalOpened(true)}>Show more</span>
      ) : (
        ""
      )}

      <FollowersModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
      />*/}
    </div>
  );
};

export default FollowersCard;
