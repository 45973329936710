import axios from 'axios';

const API = axios.create({ baseURL: 'https://labmeddz.com/api' });

// Adding the authorization token to each request if available in localStorage
API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});

// Create product

// Get all products of a shop
export const getAllProductsOfShop = (shopId) => API.get(`/products/get-all-products-shop/${shopId}`);

// Delete product of a shop
export const deleteProduct = (productId) => API.delete(`/products/delete-shop-product/${productId}`);

// Get all products
export const getAllProducts = () => API.get('/products/get-all-products');

export const getProduct = (id) => API.get(`/products/get-all-products/${id}`);

// Create or update a review for a product
export const createOrUpdateReview = (reviewData) => API.put('/products/create-new-review', reviewData);

// Get all products for admin
export const getAllProductsForAdmin = () => API.get('/products/admin-all-products');






export const getAllOrders = () => API.get('/products/admin-all-orders');
