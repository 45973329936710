import React, { useEffect, useState } from "react"
import Heading from "../common/heading/Heading"
import "../allcourses/courses.css"
import { coursesCard } from "../../dummydata"
import { getAllProducts } from "../../api/ProductsRequest"
import { useDispatch } from "react-redux"
import { addTocart } from "../../actions/CartAction"
import Swal from "sweetalert2"


const HAbout = () => {
  const [Products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await getAllProducts();
      setProducts(data.products);
    };
    fetchProducts();
  }, []);

  const extractPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const dispatch = useDispatch();

  const addToCartHandler = (product) => {
    if (product) {
      const { name, images, _id, originalPrice } = product;
      dispatch(addTocart({
        name,
        images,
        _id,
        originalPrice,
      }));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Porduct Added to cart",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };
  return (
    <>
      <section className='homeAbout'>
        <div className='container'>
          <Heading subtitle='Laboratories Products' title='Explore Best Selling Products' />

          <div className='coursesCard'>
            <div className='grid2'>
            {Products.slice(0,6).map((val) => (
            <div className="items" key={val._id}>
              <div className="mx-auto max-w-md overflow-hidden rounded-lg bg-white shadow">
                <a href={`/products/${val._id}`}>
                  <img
                    src={process.env.REACT_APP_PUBLIC_FOLDER + val.images}
                    className="aspect-video w-full object-cover"
                    alt=""
                  />
                </a>
                <div className="p-4">
                  <h3 className="text-xl font-medium text-gray-900">{val.name}</h3>
                  <p className="mt-1 text-gray-500">
                    {extractPlainText(
                      val.description?.length > 100
                        ? val.description.substr(0, 100) + "..."
                        : val.description
                    )}
                  </p>
                </div>
                <div className=" mt-auto">
                  <div className="my-4 flex gap-2">
                    <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                      {val.originalPrice} DA
                    </span>
                    <span className="inline-flex items-center gap-1 rounded-full bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600">
                      {val.shop.username}
                    </span>
                    <span className="inline-flex items-center gap-1 rounded-full bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-600">
                      Develop
                    </span>
                  </div>
                  <button onClick={() => addToCartHandler(val)} className="outline-btn">BUY NOW !</button>
                </div>
              </div>
            </div>
          ))}
            </div>
            <a href="/products"><p>View More</p></a>
          </div>
        </div>
      </section>
    </>
  )
}

export default HAbout
