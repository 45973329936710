import React from "react";
import { blog } from "../../../dummydata";
import "./footer.css";
import Logo from "../../../img/MEDLAB.png";

const Footer = () => {
  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>Newsletter - Stay tuned and get the latest updates</h1>
           
          </div>
          <div className='right row'>
            <input type='text' placeholder='Enter email address' />
          </div>
        </div>
      </section>
      <footer>
        <div className='container padding'>
          <div className='box logo'>
            <img className="logo" src={Logo} alt="logo" />

            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-instagram icon'></i>
          </div>
          <div className='box link'>
            <h3>Explore</h3>
            <ul>
             <a href="/about"> <li>About Us</li></a>
                          <a href="/contact"><li>Contact Us</li></a>
<a href="/team"><li>Team</li></a>
            </ul>
          </div>
          <div className='box link'>
            <h3>Quick Links</h3>
            <ul>
             <a href="/contact"><li>Contact Us</li></a>
              <a href="/Privacy"><li>Privacy</li></a>
              <a href="/Terms"><li>Terms & Conditions</li></a>
             <a href="/pricing"> <li>Pricing</li></a>
              
            </ul>
          </div>
          
          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className='fa fa-map'></i>
                Timgad,  Btana
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                +213 0772474809
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                info@labmeddz.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          Copyright ©2024  All rights reserved | This Platform is made with <i className='fa fa-heart'></i> by Aymen Banchouri
        </p>
      </div>
    </>
  );
};

export default Footer;
