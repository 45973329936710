import React from 'react';
import Back from '../../components/common/back/Back';
import "./style.css";

function Privacy() {
  return (
    <div className="">
      <Back title={"Privacy"}/>
      <div className="privacy-content">
        <h2>Privacy Policy</h2>
        <p><strong>1. Information we collect:</strong></p>
        <p>When you use our website or services, we may collect the following information from you:</p>
        <ul>
          <li><strong>Personal information:</strong> This includes your name, email address, phone number, and other information you provide to us when you sign up for our services.</li>
          <li><strong>Usage information:</strong> This includes information about how you use our mobile app and services, including your clicks, topics that interest you in our app, and the pages you visit.</li>
        </ul>
        <p><strong>2. How we use your information:</strong></p>
        <p>We use your personal information to provide our services to you, to communicate with you about our services, and to improve our services. We may also use your information to send you marketing communications, if you have opted in to receive them.</p>
        <p>We use usage information to analyze how our mobile app and services are used, to diagnose problems with our product and services, and to improve our app and services.</p>
        <p><strong>3. How we protect your information:</strong></p>
        <p>We use reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee the absolute security of your personal information.</p>
        <p><strong>4. Sharing of information:</strong></p>
        <p>We do not sell, rent, or share your personal information with third parties, except as described in this Privacy Policy. We may share your personal information with our service providers who need access to your personal information to provide services to us.</p>
        <p>We may also share your personal information if we believe in good faith that disclosure is necessary to comply with applicable law, regulation, legal process, or governmental request.</p>
        <p><strong>5. Your rights:</strong></p>
        <p>You have the right to access, correct, or delete your personal information that we hold about you. You may also have the right to object to or restrict our processing of your personal information, and to receive a copy of your personal information in a structured, machine-readable format.</p>
        <p><strong>6. Changes to this Privacy Policy:</strong></p>
        <p>We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on our website.</p>
        <p><strong>7. Contact us:</strong></p>
        <p>If you have any questions or concerns about this Privacy Policy, or if you want to exercise your rights regarding your personal information, please contact us via this website, our mobile app, or the information above.</p>
      </div>
    </div>
  );
}

export default Privacy;
