import React, { useEffect, useState } from "react";

import { getSlides } from "../../api/SlidesRequest";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slides.css";

function SlidesHero() {
  const [Slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const { data } = await getSlides();
        setSlides(data.Slides);
      } catch (error) {
        console.error("Error fetching slides:", error);
      }
    };
    fetchSlides();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="New" className="w-full mx-auto px-4 py-6">
      <Slider className="mx-auto w-full" {...settings} autoplay={true}>
        {Slides.map((slide) => (
          <div key={slide._id} className="card">
            <div className="card-top-top">
              <a href={slide.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={process.env.REACT_APP_PUBLIC_FOLDER + slide.image}
                  alt="slide"
                  className="object-contain"
                />
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SlidesHero;
