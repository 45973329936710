import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadImage, uploadProduct } from "../../actions/UploadAction";
import { MdDelete } from "react-icons/md";

import Back from "../../components/common/back/Back";
import "./style.css";

function AddProducts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer.authData);
  const loading = useSelector((state) => state.postReducer.uploading);

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("Personal Protective Equipment (PPE)");
  const [description, setDescription] = useState("");
  const [stock, setStock] = useState("");
  const [price, setPrice] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newProduct = {
      shopId: user._id,
      shop: user,
      name: productName,
      description,
      category,
      originalPrice: price,
      stock,
      images: image ? image.name : "",
    };

    if (image) {
      const data = new FormData();
      const fileName = Date.now() + image.name;
      data.append("name", fileName);
      data.append("file", image);
      newProduct.images = fileName;
      try {
        await dispatch(uploadImage(data));
      } catch (err) {
        console.log(err);
      }
    }
    dispatch(uploadProduct(newProduct));
    resetForm();

    // Redirect to home after submitting post
    navigate("/products");
  };

  // Handle Image Change
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImage(img);
      setImagePreview(URL.createObjectURL(img));
    }
  };

  const resetForm = () => {
    setImage(null);
    setImagePreview("");
    setProductName("");
    setDescription("");
    setCategory("Personal Protective Equipment (PPE)");
    setStock("");
    setPrice("");
  };

  const handleDeleteImage = () => {
    setImage(null);
    setImagePreview("");
  };
  return (
    <>
      <Back title="Add Product" />
      <div className="add">
        <form onSubmit={handleSubmit} className="mx-auto max-w-screen-sm">
          {/* Name */}
          <div className="mx-auto my-4">
            <label htmlFor="productName" className="mb-1 block text-lg font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="productName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="block w-full p-3 border rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
              placeholder="Product Name"
            />
          </div>
          {/* Category */}
          <div className="mx-auto my-4">
            <label htmlFor="category" className="mb-1 block text-lg font-medium text-gray-700">
              Select Category
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="block w-full p-3 border rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
            >
              <option value="Personal Protective Equipment (PPE)">Personal Protective Equipment (PPE)</option>
              <option value="Pharmaceuticals and Medications">Pharmaceuticals and Medications</option>
            </select>
          </div>
          {/* Upload Image */}
          <div className="mx-auto my-4">
      <div className="mx-auto ">
        <label htmlFor="example5" className="mb-1 block text-sm font-medium text-gray-700">
          Upload files
        </label>
        <label className="flex w-full cursor-pointer appearance-none items-center justify-center rounded-md border-2 border-dashed border-gray-200 p-6 transition-all border-primary-300">
          <div className="space-y-1 text-center">
            <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>
            </div>
            <div className="text-gray-600">
              <a href="/" className="font-medium text-primary-500 hover:text-primary-700">
                Click to upload
              </a>{' '}
              or drag and drop
            </div>
            <p className="text-sm text-gray-500">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </div>
          <input id="example5" type="file" className="sr-only" onChange={onImageChange} multiple />
        </label>
      </div>
      <div className="flex flex-wrap">
      {imagePreview && (
              <div className="relative my-4 w-32 h-32 m-2">
                <img src={imagePreview} alt="Preview" className="w-full h-full object-cover rounded-md" />
                <button
                  onClick={handleDeleteImage}
                  className="absolute delete top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                >
                  <MdDelete />
                </button>
              </div>
            )}
      </div>
    </div>

          {/* Description */}
          <div className="mx-auto my-6">
            <label htmlFor="description" className="mb-1 block text-lg font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              className="block w-full p-3 border rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
              placeholder="Product Description"
            />
          </div>
          {/* Stock */}
          <div className="mx-auto my-4">
            <label htmlFor="stock" className="mb-1 block text-lg font-medium text-gray-700">
              Stock
            </label>
            <input
              type="number"
              id="stock"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              className="block w-full p-3 border rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
              placeholder="How many Items do you have ?"
            />
          </div>
          {/* Price */}
          <div className="mx-auto my-4">
            <label htmlFor="price" className="mb-1 block text-lg font-medium text-gray-700">
              Price
            </label>
            <input
              type="number"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="block w-full p-3 border rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
              placeholder="Price"
            />
          </div>
          {/* Submit Button */}
          <div className="mx-auto my-4">
            <button
              className="inline-block w-full my-4 rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
              type="submit"
              disabled={loading}
            >
              {loading ? "Uploading" : "Add Post"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddProducts;
