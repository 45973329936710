import React, { useEffect, useState } from 'react'
import Heading from '../common/heading/Heading'
import { getDoctorsAndStudentsByRating } from '../../api/UserRequests';
import { useSelector } from 'react-redux';
import { MdStars } from 'react-icons/md';



function Best() {
    const [persons, setPersons] = useState([]);
    const { user } = useSelector((state) => state.authReducer.authData);
  
    useEffect(() => {
      const fetchPersons = async () => {
        const { data } = await getDoctorsAndStudentsByRating();
        setPersons(data);
      };
      fetchPersons();
    }, [user._id]);
return (
    <div>
       <>
      <section className='testimonal padding'>
        <div className='container'>
          <Heading subtitle='Best Users' title='Our best users' />

          <div className='content grid2'>
            {persons.map((person) => (
              <div key={person.id} className='items shadow'>
                <div className='box flex'>
                  <div className='img'>
                  <img
                className=""
                src={person.profilePicture}
                alt=""
              />  <i style={{display:"flex",alignItems:"center",gap:"3px"}}>{person?.points}<MdStars className="star" />{" "}</i>
                  </div>
                  <div className='name'>
                    <h2>{person.username}</h2>
                  </div>
                </div>
              </div>
            ))}
  </div>
          <Heading subtitle='usage of Points'  />
          <p>The points system is designed to reward active participation on the LABMED platform. Users earn points by attending events, participating in discussions, completing educational content, and more. Accumulated points can be redeemed for discounts, access to exclusive events, and other rewards.</p>
        </div>
      </section>
    </>
    </div>
  )
}

export default Best

